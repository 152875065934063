<template>
  <div style="width: 100%; height: 100%" class="sidebar">
    <div class="logo">
      <a-icon type="appstore" class="appstore-icon ml6" v-if="isSub" @click="showDrawer" />
      <img class="logo-img" v-if="partyIcon" :src="partyIcon" alt="" />
      <div class="logoMini" v-else>
        <img
          :class="` ${collapsed ? 'logoImg' : 'logoImgLeft'}`"
          src="../../assets/logoLeft.png"
          alt=""
        />
        <img
          :class="` ${collapsed ? 'logoImgC' : 'logoImgRight'}`"
          src="../../assets/logoRight.png"
          alt=""
        />
      </div>
    </div>
    <a-menu
      :open-keys="openKeys"
      :selected-keys="[getRouteName()]"
      class="sideMenu"
      mode="inline"
      :inlineIndent="12"
      :inline-collapsed="collapsed"
      @click="handleMenuClick"
      @openChange="handleOpenChange"
    >
      <template v-for="item in menu_list">
        <a-sub-menu :key="item.view || item.name" v-if="item.children && item.children.length">
          <span slot="title">
            <div class="anticon">
              <i class="icon" :class="item.icon"></i>
            </div>
            <span>{{ $t(`menu.${item.view || item.name}`) }}</span>
          </span>
          <template v-for="elem in item.children">
            <a-menu-item :key="elem.view" :title="$t(`menu.${elem.view}`)">
              {{ $t(`menu.${elem.view}`) }}
            </a-menu-item>
          </template>
        </a-sub-menu>
        <a-menu-item :key="item.view || item.name" v-else>
          <div class="anticon">
            <i class="icon" :class="item.icon"></i>
          </div>
          <span>{{ $t(`menu.${item.view || item.name}`) }}</span>
        </a-menu-item>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { mapState, mapMutations } from 'vuex'
import title from '@common/title'
import { eventBus } from '@/utils/eventBus'
export default {
  name: 'Sidebar',
  props: ['collapsed'],
  components: {
    'a-menu': Menu,
    'a-sub-menu': Menu.SubMenu,
    'a-menu-item': Menu.Item,
  },
  computed: {
    ...mapState(['panes']),
    partyIcon() {
      return this.$store.state.userSession?.merchantLogoUrl
    },
    isSub() {
      return window.__POWERED_BY_QIANKUN__
    },
  },
  data() {
    return {
      openKeys: [],
      menu_list: [],
    }
  },
  mounted() {
    this.handleMenuOpenedKeys()
    this.initMenu()
    localStorage.setItem('appCode', 'OMS')
    eventBus.$on('menuChange', this.initMenu)
  },
  unmounted() {
    eventBus.$off('menuChange', this.initMenu)
  },
  watch: {
    $route(newVal) {
      let key = newVal.path.split('/')[1]
      this.openKeys = [key]
      this.chooseActivePage(this.menu_list, newVal.name)
    },
  },
  methods: {
    ...mapMutations(['setState', 'addPane']),
    chooseActivePage(list, viewName) {
      for (let i = 0; i < list.length; i++) {
        let data = list[i]
        if (data.view === viewName) {
          if (data.leaf) {
            this.global.currentModule = data
            title({
              session: this.global.currentSession,
              module: this.global.currentModule,
            })
          }
          let parent = data
          while (parent) {
            parent.active = true
            parent = parent.parent
          }
          return true
        }
        if (data.children) {
          if (this.chooseActivePage(data.children, viewName)) break
        }
      }
    },
    async initMenu(list) {
      let data = list || this.$store.state.menuList || []
      if (!data.length) {
        return
      }
      const viewName = this.getRouteName()
      let bind = (data, parent) => {
        if ((data.view || '') == viewName) {
          data.active = true
        }
        data.parent = parent
        if (!data.children) return
        let children = data.children
        for (let i = 0; i < children.length; i++) {
          bind(children[i], data)
        }
      }
      let activeFalse = (data) => {
        this.$set(data, 'active', false)
        if (!data.children || data.children.length <= 0) return
        for (let i = 0; i < data.children.length; i++) {
          activeFalse(data.children[i])
        }
      }
      for (let i = 0; i < data.length; i++) {
        bind(data[i])
        activeFalse(data[i])
      }
      this.chooseActivePage(data, viewName)
      this.menu_list = [...data]
    },
    handleMenuOpenedKeys() {
      this.openKeys = [this.$route.path.replace(/[^/]+(?!.*\/)/, '').replace(/\//g, '')]
    },
    handleOpenChange(openKeys) {
      this.openKeys = openKeys
    },
    handleMenuClick(route) {
      if (route.key === this.getRouteName()) return
      this.addPane({ view: route.key })
      this.$router.push({ name: route.key })
    },
    getRouteName() {
      let fullPath = this.$route.fullPath
      return fullPath.split('/').reverse()?.[0]
    },
    showDrawer() {
      this.$showAppDrawer()
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  background: $primary-color;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .logo-img {
    width: 90px;
    height: 42px;
    object-fit: contain;
  }
  .appstore-icon {
    cursor: pointer;
    color: #fff;
    font-size: 24px;
  }
}
.icon {
  margin-right: 6px;
  font-size: 16px;
}
</style>

<style lang="scss">
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  font-size: 13px;
}
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   color: #fff;
// }
.logoImgLeft {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  transition: all 0.3s;
}
.logoImgRight {
  width: 100px;
  height: 13px;
  transition: all 0.3s;
}
.logoImg {
  width: 30px;
  height: 30px;
  transition: all 0.3s;
}
.logoImgC {
  display: none;
  transition: all 0.3s;
}
</style>
